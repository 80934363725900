import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { LoginRepository } from "../../repositories/login";

const Logout = (props) => {
    useEffect(() => {
        new LoginRepository().logOut();
    }, []);

    return <></>;
};

Logout.propTypes = {
    history: PropTypes.object,
};

export default withRouter(Logout);
