import { axiosApi } from "../helpers/api_helper";

export class UserByStoreRepository {
  endpoint = "/users_by_store";

  async getUserByStore(params) {
    const response = await axiosApi.get(this.endpoint, { params });
    return response.data;
  }

  async create(data) {
    const response = await axiosApi.post(this.endpoint, data);
    return response;
  }

  async delete(id) {
    const response = await axiosApi.delete(`${this.endpoint}/${id}`);
    return response.data;
  }

  async update(id, data) {
    const response = await axiosApi.patch(`${this.endpoint}/${id}`, data);
    return response.data;
  }

  async sendCode(email) {
    const response = await axiosApi.post(
      `${this.endpoint}/send-reset-pasword-code`,
      { email }
    );
    return response;
  }

  async validateCode(code) {
    const response = await axiosApi.post(
      `${this.endpoint}/validate-code/${code}`
    );
    return response;
  }

  async changePassword(payload) {
    const response = await axiosApi.post(
      `${this.endpoint}/reset-pasword/`,
      payload
    );
    return response;
  }
}

export default UserByStoreRepository;
