import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drawer: {
    isOpen: false,
  },
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleDrawer: (state, action) => {
      const drawer = { ...state.drawer };
      drawer.isOpen = !drawer.isOpen;
      state.drawer = drawer;
    },
    setDrawer: (state, action) => {
      const drawer = { ...state.drawer };
      drawer.isOpen = action.payload;
      state.drawer = drawer;
    },
  },
});
export const uiActions = uiSlice.actions;
export default uiSlice.reducer;
