import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  saving: false,
  users: [],
  page: 1,
  count: 0,
  isOpen: false,
  editing: null,
};

export const storeUserSlice = createSlice({
  name: "store/users",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
    },
    loaded: (state, action) => {
      state.isLoading = false;
      state.users = action.payload.result;
      state.count = action.payload.total_pages;
    },
    error: (state, action) => {
      state.isLoading = false;
      state.saving = false;
    },
    save: (state, action) => {
      state.saving = true;
    },
    create: (state, action) => {
      state.saving = false;
      state.users = [...state.users, action.payload];
      state.isOpen = false;
    },
    open: (state, action) => {
      state.isOpen = true;
    },
    close: (state, action) => {
      state.isOpen = false;
    },
    edit: (state, action) => {
      state.editing = action.payload;
    },
    edited: (state, action) => {
      state.editing = null;
    },
    deleted: (state, action) => {
      state.users = [
        ...state.users.filter((user) => user.id !== action.payload),
      ];
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    update: (state, action) => {
      state.saving = false;
    },
    updated: (state, action) => {
      state.users = state.users.map((user) => {
        if (user.id === action.payload.id) {
          return { ...user, ...action.payload };
        }
        return user;
      });
      state.saving = false;
      state.editing = false;
    },
    setEditing: (state, action) => {
      state.isOpen = false;
      state.editing = action.payload;
    },
  },
});
export const storeUserActions = storeUserSlice.actions;
export default storeUserSlice.reducer;
