import { combineReducers } from "@reduxjs/toolkit";

import prizeRedeemed from "./prizeRedeemed/slice";
import schedule from "./schedule/slice";
import users from "./users/slice";
import members from "./members/slice";

export default combineReducers({
  prizeRedeemed,
  schedule,
  usersByStore: users,
  members,
});
