import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sending: false,
  sent: false,
  validated: false,
  changed: false,
  email: "",
  error: "",
  code: "",
};

export const authRecovery = createSlice({
  name: "auth/recovery-password",
  initialState,
  reducers: {
    send: (state, action) => {
      state.sending = true;
      state.email = action.payload;
    },
    sended: (state, _) => {
      state.sending = false;
      state.sent = true;
    },
    sendFailed: (state, _) => {
      state.sending = false;
      state.sent = false;
    },
    validating: (state, action) => {
      state.sending = false;
      state.validated = false;
      state.error = "";
      state.code = action.payload;
    },
    validated: (state, _) => {
      state.sending = false;
      state.validated = true;
    },
    invalid: (state, _) => {
      state.sending = false;
      state.validated = false;
      state.error = "Código invalido";
    },
    changing: (state, _) => {
      state.sending = false;
    },
    changed: (state, _) => {
      state.sending = false;
      state.changed = true;
    },
    reset: (state, _) => {
      state.sending = false;
      state.sent = false;
      state.validated = false;
      state.error = "";
      state.email = "";
      state.code = "";
    },
  },
});
export const authRecoveryActions = authRecovery.actions;
export default authRecovery.reducer;
