import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  items: [],
};

export const countrySlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.items = [];
    },
    loaded: (state, action) => {
      state.isLoading = false;
      state.items = action.payload;
    },
  },
});
export const countrySliceActions = countrySlice.actions;
export default countrySlice.reducer;
