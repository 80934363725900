import { axiosApi } from "../helpers/api_helper";

export class CountriesRepository {
    endpoint = "/countries";

    async listAll() {
        const response = await axiosApi.get(`${this.endpoint}`);
        return response.data;
    }
}

export default CountriesRepository;
