import { axiosApi } from "../helpers/api_helper";

export class StoreCategoriesRepository {
    endpoint = "/stores_categories";

    async listAll() {
        const response = await axiosApi.get(`${this.endpoint}`);
        return response.data;
    }
}

export default StoreCategoriesRepository;
