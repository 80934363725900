import { axiosApi } from "../helpers/api_helper";
import { AUTH_USER_KEY, TOKEN_KEY } from "../routes/constants";

export class LoginRepository {
  endpoint = "/auth";

  login(payload) {
    return new Promise(async (resolve, reject) => {
      await axiosApi
        .post(`${this.endpoint}/login`, payload)
        .then((response) => {
          if (response.data) {
            const data = response.data;
            this.setUser(data.user_data);
            this.setToken(data.token);
            resolve(data);
          }
          reject({ message: "credenciales invalidas" });
        })
        .catch((error) => reject(error));
    });
  }

  async register(payload) {
    const response = await axiosApi.post(`${this.endpoint}/register`, payload);
    const data = response.data;
    if (response.status === 201) {
      this.setUser(data.user_data);
      this.setToken(data.token);
      return response.data;
    }

    throw new Error(response.response.data.message);
  }

  logout() {
    localStorage.clear();
    window.location.replace("/");
  }

  setData(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getData(key) {
    let data = localStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
  }

  getInfoUser() {
    return this.getData(AUTH_USER_KEY);
  }

  setUser(data) {
    this.setData(AUTH_USER_KEY, data);
  }

  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  setToken(data) {
    localStorage.setItem(TOKEN_KEY, data);
  }

  logOut() {
    localStorage.removeItem(AUTH_USER_KEY);
    localStorage.removeItem(TOKEN_KEY);
  }
}

export default LoginRepository;
