import i18next from "i18next";
import { toast } from "react-toastify";
import UserByStoreRepository from "../../../../../repositories/userByStore";
import { authRecoveryActions } from "./slice";

const toastConfig = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const sendCode = (email) => async (dispatch) => {
  dispatch(authRecoveryActions.send(email));
  const repository = new UserByStoreRepository();
  try {
    const response = await repository.sendCode(email);
    if (response.status === 201) {
      dispatch(authRecoveryActions.sended());
    } else {
      dispatch(authRecoveryActions.sendFailed());
      toast.info(
        response.response?.data?.message ||
          i18next.t("It seems something has gone wrong, please try again"),
        toastConfig
      );
    }
  } catch (e) {
    toast.info(
      e.response?.data?.message ||
        i18next.t("It seems something has gone wrong, please try again"),
      toastConfig
    );
  }
};

export const validateCode = (code) => async (dispatch) => {
  dispatch(authRecoveryActions.validating(code));
  const repository = new UserByStoreRepository();
  try {
    const response = await repository.validateCode(code);
    if (response.status === 201) {
      dispatch(authRecoveryActions.validated());
    } else {
      dispatch(authRecoveryActions.invalid());
      toast.info(
        response.response?.data?.message ||
          i18next.t("It seems something has gone wrong, please try again"),
        toastConfig
      );
    }
  } catch (e) {
    toast.info(
      e.response?.data?.message ||
        i18next.t("It seems something has gone wrong, please try again"),
      toastConfig
    );
  }
};

export const changePassword = (payload) => async (dispatch) => {
  dispatch(authRecoveryActions.changing());
  const repository = new UserByStoreRepository();
  try {
    const response = await repository.changePassword(payload);
    if (response.status === 201) {
      toast.info("La contraseña ha sido actualizada", toastConfig);
      dispatch(authRecoveryActions.changed());
    } else {
      toast.info(
        response.response?.data?.message ||
          i18next.t("It seems something has gone wrong, please try again"),
        toastConfig
      );
    }
  } catch (e) {
    toast.info(
      e.response?.data?.message ||
        i18next.t("It seems something has gone wrong, please try again"),
      toastConfig
    );
  }
};
